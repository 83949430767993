import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
export default createStore({
  state: {
    userInfomm:{},
    userInfomm_gunzhu:[],
    vuexIslogin:0,  //0位登录，  1登录
    vuserid:'',  //用户id
    verName:'',  //用户昵称
    count:0, //调用登录
    likeObjvuex:{},
    darkMode:false,
    search:""
  },
  getters: {
  },
  mutations: {
    darkModeFT(state,data){
      state.darkMode = data
    },
    searchF(state,data){
      state.search = data
    },
    systemImgelike(state,data){
      state.likeObjvuex = data
    },
    increment(state) {
      state.count++
    },
    //修改用户关注的主播
    addGunzhuAnotor(state,data){
      if(data.type == 'add'){
        state.userInfomm_gunzhu = data.data
      }else{
        state.userInfomm_gunzhu.push(...data.data)
      }
    },
    delGunzhuAnotor(state,data){
      state.userInfomm_gunzhu.splice(data,1)
    },
    //修改用户主体信息
    addUserInfomm(state,data){
      state.userInfomm = data
      state.vuexIslogin = 1
    },
    //修改用户手机号
    addUserInfommPhone(state,data){
      state.userInfomm.phone = data
    },
    //修改用户违禁次数
    addUserInfommviolation_num(state,data){
      state.userInfomm.violation_num = data
    },
    //更改用户信息
    addVuservuex(state,data){
      state.vuserid = data[0]   //用户id
      state.verName = data[1]    //用户name
    },

    removeUserInfomm(state){
      state.userInfomm = {}
      state.userInfomm_gunzhu = []
      state.vuexIslogin = 0
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [
    createPersistedState(),
  ],
})
