import axios from 'axios'
import { ElMessage } from "element-plus";
import store from '@/store';
// import jigouZim from "@/until/index.js";
import { baseURL } from './connector'
// import qs from 'qs'
// 创建一个 Axios 实例
const instance = axios.create({
  baseURL,
  timeout: 30000, // 请求超时时间
})

// 添加请求拦截器
instance.interceptors.request.use(config => {
  let userInfomm = JSON.parse(window.localStorage.getItem("userInfomm")) || {};
  let TOKEN = userInfomm == '{}' ? '':userInfomm.token 
  if (config.method == 'post' || config.method == 'get') {
    if(TOKEN){
      config.headers.token = TOKEN;
      if(!config.data){
        config.data = {uid:''}
      }
      config.data.uid = userInfomm.id
    }
    config.data = new URLSearchParams(config.data)
  }
  return config
}, error => {
  return Promise.reject(error)
})

// 添加响应拦截器
  instance.interceptors.response.use(response => {
    const res = response.data || {};
      if(res.code == '200'){
        return Promise.resolve(response.data)
      }else if(res.code == 20099){
        let V = true
        try{
          if(V){
            V = false
            ElMessage({ type: "warning", message: "账号已在其他地方登录!" });
            window.localStorage.removeItem("userInfomm")
            store.commit("removeUserInfomm");
            store.commit("addVuservuex", ["", ""]);
            // jigouZim.jiglogout();
            // setTimeout(() => {
            //   jigouZim.loginZim();
            // }, 1000);
            location.reload();
          }
        }catch(err){
          console.log('catch',err)
        }
      }else{
        return Promise.reject(response.data)
      }
  
    // 处理响应数据（如果需要）
  }, error => {
    // 处理响应错误（如果需要）
    return Promise.reject(error)
  }) 

export default instance