import { ZIM } from "zego-zim-web";
import { generateToken } from './config.js';
import { appConfig } from './config.js';
const zim = ZIM.create(appConfig);
import store from "@/store"
export { zim };

export default {
    // 登录即购
    loginZim(userID, userName, type) {
        //判断是否是登录

        if (store.state.vuexIslogin == 1) {

            let loginForm = {
                userID: store.state.userInfomm.id,
                userName: store.state.userInfomm.nickname,
            };
            console.log('登录了',loginForm)
            const token = generateToken(loginForm.userID);
                zim.login(loginForm, token)
                .then((res) => {
                    // 登录成功
                    console.log("登录成功");  
                    this.updateUserName(store.state.userInfomm.nickname)               
                    // return true;
                })
                .catch((err) => {
                    // 登录失败
                    console.log("登录失败");
                    console.log(err)
                    // return false;
                });
          
        } else {

            let loginForm = {}
            if (!store.state.vuserid) {
                // 生成一个8位的随机整数
                const randomNumber = Math.floor(Math.random() * 100000000);
                // 将随机整数限制在8位数范围内，并转换为字符串
                const eightDigitNumber = String(randomNumber).substring(0, 8);
                const eightname = this.generateFanID()
                loginForm = {
                    userID: 'mm' + eightDigitNumber,
                    userName: eightname,
                };
            } else {
                loginForm.userID = store.state.vuserid
                loginForm.userName = store.state.verName
            }

            let zimToken = generateToken(loginForm.userID);

            zim.login(loginForm, zimToken)
                .then(() => {
                    // 登录成功
                    console.log("游客登录成功");
                    store.commit('addVuservuex', [loginForm.userID, loginForm.userName])
                })
                .catch((err) => {
                    // 登录失败
                    console.log("游客登录失败", loginForm);
                    console.log(err);
                });
        }
    },
    // 随机生成游客姓名
    generateFanID() {
        // 生成三个随机数字
        const randomNumbers = Math.floor(Math.random() * 1000); // 生成 0 到 999 的随机整数
        const paddedNumbers = String(randomNumbers).padStart(3, '0'); // 保证数字是三位数，并在前面补零
      
        // 生成一个随机字母
        const randomLetter = String.fromCharCode(65 + Math.floor(Math.random() * 26)); // 生成 A 到 Z 之间的随机字母
      
        // 组合生成球迷编号
        const fanID = `球迷${paddedNumbers}${randomLetter}`;
      
        return fanID;
    },
    // 退出登录
    jiglogout() {
        zim.logout()
        console.log('退出登录')
    },

    // 创建房间
    enterRoom(roomInfo, { }) {
        if (!roomInfo.roomID) return
        console.log('创建房间', roomInfo)
        let config = {
            roomDestroyDelayTime:3*60*60
        }
        return zim.enterRoom(roomInfo, config)
            .then((res) => {
                // 创建房间成功
                console.log("创建房间成功");
                return true;
            })
            .catch((err) => {
                // 创建房间失败
                console.log("创建房间失败", err);
                if(err.code == 6000121){
                    this.loginZim()
                }
                return false;
            });

    },

    // 离开房间
    leaveRoom(roomId) {

        zim.leaveRoom(roomId)
            .then((res) => {
                // 离开房间成功
                console.log("离开房间成功");
                return true;
            })
            .catch((err) => {
                // 离开房间失败
                console.log("离开房间失败", err);
                return false;
            });
    },

    // 发送消息
    sendMes(roomId, message, userName,userLevel,color,Anchor) {
        var toConversationID = roomId; // 对方 userID
        var conversationType = 1; // 会话类型，取值为 单聊：0，房间：1，群组：2
        var config = {
            priority: 2, // 设置消息优先级，取值为 低：1（默认），中：2，高：3
        };
        var notification = {
            onMessageAttached: function (message) { }
        }
        // 附加消息
        let exTendDate = JSON.stringify(
            {
                Anchor,
                userName: userName,
                userLevel,
                color,
            }
        );
        console.log('exTendDate',exTendDate)
        var messageTextObj = { type: 1, message: message,extendedData:exTendDate};
       return zim.sendMessage(messageTextObj, toConversationID, conversationType, config, notification)
            .then(({ message }) => {
                // 发送成功
                console.log("发送成功");
                return true;
            })
            .catch((err) => {
                // 发送失败
                console.log("发送失败", err);
                return false;
            });
    },
    // 更新用户头像
		updateUserAvatarUrl(headImg){
			return zim.updateUserAvatarUrl(headImg).catch(()=>{});
		},
		// 更新用户昵称
		updateUserName(userName){
			return zim.updateUserName(userName).catch(()=>{});
		},
}