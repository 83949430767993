<template>
  <div class="app">
    <router-view :class="darkMode?'night':'bright'" />
  </div>
</template>

<script>

import {onMounted, onBeforeMount, reactive, computed, toRefs} from 'vue'
import { useStore } from "vuex";
export default {

  setup() {
    const store = useStore();
    const state = reactive({
      darkMode: computed(() => store.state.darkMode),
    });
    const handleResize = ()=>{
      if(window.innerWidth < 800){
        window.location.href = 'https://www.mengmazb.com/h5';
      }
    }
    onBeforeMount(()=>{
      handleResize()
    })
    return {
      ...toRefs(state),
    };

  },


};


</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
  box-sizing:
   border-box;
}
body{
  line-height: 1.6;
    font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-size: 15px;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
}
.bright{
  background-color: #f6f6f6;
}
.night{
  background-color: rgba(19, 19, 19);
}
// .app{
//   background-color: #fff;
// }
.xgplayer .xgplayer-start,.danmu-icon,.xgplayer-cssfullscreen{
  display: none;
}

.xgplayer .xgplayer-danmu > * {
    font-size: 21px !important;
    font-weight:bold !important
}
.xgplayer .xg-left-grid>:first-child{
  transform: scale(.8)
}
.xgplayer .xg-right-grid>:first-child{
  transform: scale(.9)

}
</style>
