import request from './request'

//热门主播
export function usersAttenAnchorsShow(params) {
  return request.post('/Personal/usersAttenAnchorsShow', params)
}
//请求直播列表
export function popularMatchsForNows(params) {
  return request.post('/Personal/popularMatchsForNows', params)
}
//首页顶部直播推荐
export function nowLiveingAnchor(params) {
  return request.post('/Personal/nowLiveingAnchor', params)
}
// 赛程列表
export function scoreScheduleShow(params) {
  return request.post('/Personal/scoreScheduleShow', params)
}
// 比赛详情
export function competitionAnchor(params) {
  return request.post('/Personal/competitionAnchor', params)
}
//非比赛详情
export function competitionDetails(params) {
  return request.post('/Personal/competitionDetails', params)
}
//获取短信验证码
export function GetSmsCode(params) {
  return request.post('/UserLogin/GetEnrollSmsCode', params)
}
//获取地区码数据
export function GetCountryPhonePrefix(params) {
  return request.get('/UserLogin/GetCountryPhonePrefix', {params})
}
 //注册账号
export function UserLoginUserEnroll(params) {
  return request.post('/UserLogin/UserEnroll', params)
}
//登录
export function UserLoginUserLogin(params) {
  return request.post('/UserLogin/UserLogin', params)
}
//获取用户信息
export function UserLoginGetUserInfo(params) {
  return request.post('/UserLogin/GetUserInfo', params)
}
//修改用户信息
export function UserLoginChangeUserInfo(params) {
  return request.post('/UserLogin/ChangeUserInfo', params)
}
//验证手机号验证码 Home
export function UserLoginCheckSmsCode(params) {
  return request.post('/UserLogin/CheckSmsCode', params)
}

//修改信息验证手机短息
export function GetChangeUserInfoSmsCode(params) {
  return request.post('/UserLogin/GetChangeUserInfoSmsCode', params)
}
//修改密码
export function UserLoginChangePassword(params) {
  return request.post('/UserLogin/ChangeUserPassword', params)
}
// 获取敏感词
export function GetSensitiveWord(params) {
  return request.get('/UserLogin/GetSensitiveWord', {params})
}
//账号封禁
export function violationMonitoring(params) {
  return request.post('/Redparam/violationMonitoring', params)
}
//记录用户活跃时间
export function loginRecord(params) {
  return request.post('/Personal/loginRecord', params)
}

//用户打开网站时调用
export function ReduserenteruserOpenAps(params) {
  return request.post('/Reduserenter/userOpenAps', params)
}
//用户关闭设备时调用
export function ReduserenterUserCloseAps(params) {
  return request.post('/Reduserenter/userCloseAps', params)
}
//用户进入直播间
export function PersonalCompetitionAnchor(params) {
  return request.post('/Personal/competitionAnchor', params)
}
//用户离开直播间
export function RedparamUserDetailNumberClean(params) {
  return request.post('/Redparam/userDetailNumberClean', params)
}
//查询用户是否被封禁
export function ReduserenteruserIsBanned(params) {
  return request.post('/Reduserenter/userIsBanned', params)
}
// 获取被封禁用户列表
export function RedparamreturnBIfgUid(params) {
  return request.post('/Redparam/returnBIfgUid', params)
}
// 调用全员推送消息
export function SendMessageToAllUsers(params) {
  return request.post('/Zego/SendMessageToAllUsers', params)
}
//获取被删除的消息列表
export function PersonalgetDelMsgList(params) {
  return request.post('/Personal/getDelMsgList', params)
}

//删除某用户信息
export function UserLoginDelMsgList(params) {
  return request.post('/UserLogin/DelMsgList', params)
}
//用户关注主播  https://test.xm888.app/Personal/attenAnchors
export function PersonalAttenAnchors(params) {
  return request.post('/Personal/attenAnchors', params)
}
//获取用户关注主播列表 https://test.xm888.app

export function getNowLiveAnchorsList(params) {
  return request.post('/Personal/getNowLiveAnchorsList', params)
}
//获取正在关注的主播列表 https://test.xm888.app/Personal/getNowLiveAnchors
export function getNowLiveAnchors(params) {
  return request.post('/Personal/getNowLiveAnchors', params)
}

//添加到通讯录
export function addFriendGood(params) {
  return request.post('/Reduserenter/addFriendGood', params)
}
//请求系统消息
export function getVideoLogoMsg(params) {
  return request.post('/Personal/getVideoLogoMsg', params)
}

//请求系统消息 (图片链接)
export function LeNewdownloadPath(params) {
  return request.post('/LeNew/downloadPath', params)
}

// 云信 删除某一条消息
export function TenctenImdelMsguUser(params) {
  return request.post('/TenctenIm/delMsguUser', params)
}
//获取赛程页热门联赛(同手机端)
export function getLeagueArr(params) {
  return request.post('/LeNew/getLeagueArr', params)
}
///Personal/scoreScheduleShowNew
//新赛程页
export function scoreScheduleShowNew(params) {
  return request.post('/Personal/scoreScheduleShowNew ', params)
}
//关注比赛
export function atten_match(params) {
  return request.post('/Redparam/atten_match ', params)
}
//取消关注比赛
export function atten_match_del(params) {
  return request.post('/Redparam/atten_match_del ', params)
}
//获取广告
export function getAdImg(params) {
  return request.post('/Personal/getAdImg ', params)
}
//搜索框
export function getSearchIndex(params) {
  return request.post('/LeNew/getSearchIndex', params)
}
// 
// 首页下的20场推荐比赛
export function bannerFollowingMatchs(params) {
  return request.post('/Personal/bannerFollowingMatchs', params)
}
// 获取主播比赛赛况信息 https://test.xm888.app/Home/Personal/getMatchData
export function PersonalgetMatchData(params) {
  return request.post('/Personal/getMatchData', params)
}
//实例 get请求
// export function getGoodsList(params) {
//   return request.get('/api/goods', { params })
// }
