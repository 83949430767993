import { createRouter , createWebHistory , createWebHashHistory} from 'vue-router'
// createWebHistory
import Index from '@/views/index/index'
import Home from '@/views/home/home'

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index,
    redirect:'/home',
    children:[{
      path:'home',
      name:'home',
      component:Home
    },{
      path:'appinstall',
      name:'appinstall',
      component:() => import('@/views/appinstall/appinstall')
    },{
      path:'schedule',
      name:'schedule',
      component:() => import('@/views/schedule/schedule')
    },{
      path:'liveInfo',
      name:'liveInfo',
      component:() => import('@/views/liveInfo/liveInfo')
    },{
      path:'saicheng',
      name:'saicheng',
      component:() => import('@/views/saicheng/saicheng')
    },{
      path:'course',
      name:'course',
      component:() => import('@/views/footerFile/course')
    },{
      path:'commonProblem',
      name:'commonProblem',
      component:() => import('@/views/footerFile/commonProblem')
    },{
      path:'agreement',
      name:'agreement',
      component:() => import('@/views/footerFile/agreement')
    },{
      path:'userinfo',
      name:'userinfo',
      meta:{
        userIndex: true, // 标记是否需要登录返回首页
      },
      component:() => import('@/views/userinfo/userinfo')
    },{
      path:'editphone',
      name:'editphone',
      component:() => import('@/views/editphone/editphone')
    },{
      path:'editpassword',
      name:'editpassword',
      component:() => import('@/views/editpassword/editpassword')
    },{
      path:'forgetpassword',
      name:'forgetpassword',
      component:() => import('@/views/forgetpassword/forgetpassword')
    },{
      path:'block',
      name:'block',
      component:() => import('@/views/block')
    },{
      path: 'selor',
      name:'selor',
      component: ()=> import('@/views/selor/schedule')
    }]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
router.beforeEach((to, from, next) => {
  let userInfo = JSON.parse(localStorage.getItem('userInfomm')) || '{}'
  if(to.meta.userIndex && userInfo == '{}'){
    next('/home')
  }else{
    next();
  }
  // 路由跳转后，让页面回到顶部
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
});

export default router
