import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import * as utils from './until/upmethods.js'; 
import { baseURL } from './axios/connector'



const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.config.globalProperties.$utils = utils;
app.config.globalProperties.$imgUrl = baseURL+'/UserLogin/ChangeUserInfo'


  app.use(ElementPlus, { size: 'small', zIndex: 3000 }) 
app.use(store).use(router).use(ElementPlus).mount('#app')
