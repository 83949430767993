<template>
  <div class="index" >
    <img src="@/assets/infoicon/indexbg22c598de.png" alt="" class="indexTopimg" v-if="$route.name == 'home' && !darkMode">
    <img src="@/assets/infoicon/indexbgdad7aac.png" alt="" class="indexTopimg" v-else-if="$route.name == 'home' && darkMode">

    <div is="vue:Header"></div>
    <div class="container"><router-view/></div>
    <div is="vue:Footer"></div>
    <div class="navright">
      <p @click="scrollToTop"><img src="@/assets/images/topimn.png" alt=""></p>
      <p v-if="upadnCons">
        <div class="upadn">
          <div class="upadn-cons">
            <img src="@/assets/images/deld.png" alt="" @click="upadnCons = !upadnCons">
            <p>下载猛犸体育 APP，享掌上高清观影</p>
            <p>专业主播分析，高清流畅无广告</p>
            <p>
              <span>Live Sports</span>
              <span class="spn2" @click="wayRouter">立即下载</span>
            </p>
          </div>
        </div>
      </p>
    </div>
  </div>
</template>

<script setup>
import { reactive,computed, toRefs ,ref } from "vue";
import { useStore } from "vuex";
import {  useRouter, useRoute  } from 'vue-router'
import Header from "../header/header.vue";
import Footer from "../footer/footer.vue";
  const store = useStore();
  const upadnCons = ref(true)
  const router = useRouter()
  const darkMode = computed(()=>store.state.darkMode)
  // const state = reactive({
  //   userInfo: computed(() => store.state.userInfomm), //用户信息
  //   darkFooter: computed(() => store.state.darkMode)
  // })
  // const stateRefs = toRefs(state)

      //返回顶部
  const scrollToTop = () => {
      // 滚动到页面顶部
      window.scrollTo({
        top: 0, // 设置滚动到的距离为0，即顶部
        behavior: "smooth", // 缓慢滚动
      });
  };

  const wayRouter = ()=>{
    router.push({
      path:"appinstall"
    })
  }

</script>

<style lang="scss" scoped>
.index {
  .navright{
    position: fixed;
    right: 20px;
    bottom: 15%;
    z-index: 101;
    color:#fff;
    .upadn{
      width: 350px;
    position: absolute;
    right: 45px;
    bottom: -20px;
    transition: all .3s;
      .upadn-cons{
        width: 330px;
      padding: 18px 18px;
      padding-bottom: 10px;
      border-radius: 10px;
      background: linear-gradient(90deg, #128bf2, #00a2a0);
      img{
        position: absolute;
        top: 5px;
        right: 28px;
        width: 10px;
        height: 11px;
      }
      p:first-of-type{
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 5px;
      }
      p:nth-of-type(2){
        font-size: 13px;
        margin: 2px 0;
        font-weight: 400;
      }
      p:nth-of-type(3){
        display: flex;
        align-items: center;
        justify-content: space-between;
        span:first-of-type{
          font-size: 33px;
          opacity: .1;
          color: #fff;
          font-family: Impact;
        }
        span:nth-of-type(2){
          width: 120px;
          color: #108ee9;
          font-size: 14px;
          line-height: 35px;
          text-align: center;
          background: #fff;
          border-radius: 20px;
          cursor: pointer;
        }
      }
      }
   
    }
    p{
      margin-bottom: 10px;
      position: relative;
      img{
        width: 50px;
        height: 50px;
        cursor: pointer;
      }
    }
  }
  .indexTopimg{
    width:100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
 
  .appVideo {
    position: fixed;
    right: 50%;
    bottom: 30px;
    background-color: #000;
    video {
      width: 400px;
      height: 200px;
    }
  }
  .container {
    width: 100%;
    min-height: calc(100vh - 196px);
    // background-color: #f0f1f6;
  }
}
</style>
