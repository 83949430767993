<template>
  <div :class="darkMode ? 'dark' : ''" class="header">
    <div class="headerTopFlex">
      <div class="headerCenter">
        <div class="imglogo">
          <div class="nav-top-conten-right">
            <router-link to="/home" >
              <img src="@/assets/images/logo.png" v-if="darkMode"/>
              <img src="@/assets/images/logo2.png" v-else/>
            </router-link>
          </div>
        </div>
        <div class="headerContant" >
          <div class="heraderNav" v-if="$route.name != 'home'">
            <div :class="$route.name == 'home' ? 'active' : ''" class="div">
              <a @click="spikRouter('/home')">首页</a>
            </div>
            <div :class="$route.name == 'saicheng' ? 'active' : ''" class="div">
              <a @click="spikRouter('/saicheng')">赛程</a>
            </div>
            <div :class="$route.name == 'schedule' ? 'active' : ''" class="div">
              <a @click="spikRouter('/schedule')">回放</a>
            </div>
            <div class="div">
              <a :href="userInfoAds.ad_url" target="_blank" >QXQ</a>
            </div>
            <div :class="$route.name == 'appinstall' ? 'active' : ''" class="div" id="heraderDiv">
              <a @click="spikRouter('/appinstall')">下载</a>
              <img class="hotImges" src="https://mengmazb.com/Public/icon/hot.svg">
            </div>
          </div>

          <div class="selor">
            <el-input
              v-model="search"
              type="large"
              :class="$route.name == 'home' ? 'selorInputOne' :'selorInputTwo'"

              placeholder="搜索"
              :clearable="true"
            />
            <div class="selor-right" @click="searchClock">
              <img src="@/assets/images/search.png" alt="" />
            </div>
          </div>
        </div>
        <div class="userContre" :class="$route.name == 'home'? 'userContre2':''">
          <div class="stydarke">
            <img src="@/assets/images/darkWhite.png" v-if="darkMode"/>
            <img src="@/assets/images/darkBlack.png" v-else/>
            <div class="fontss">
              <span v-if="darkMode">深色模式</span>
              <span v-else>浅色模式</span>
            </div>
            <el-switch
              v-model="darkMode"
              class="ml-2"
              size="default"
              style="
                --el-switch-on-color: #17c763;
                --el-switch-off-color: #807e7e;
              "
              @change="changeChecked1"
            />
          </div>
          <div class="user">
            <div class="no-user" v-if="JSON.stringify(userInfo) == '{}'" @click="dialogVisibleLogin = true">
              <img src="@/assets/images/userant1.png" alt="" v-if="darkMode"/>
              <img src="@/assets/images/userant2.png" alt="" v-else/>
              登录
            </div>
            <!-- <el-avatar v-if="JSON.stringify(userInfo) != '{}'" :size="38" :src="userInfo.headimg" style="cursor: pointer;" @click="skipRouteInfo"/> -->
            <div class="avatar" v-if="JSON.stringify(userInfo) != '{}'">
          <el-avatar
            :size="45"
            :src="userInfo.headimg || circleUrl"
            class="avatarCss"
            @click="skipRouteInfo"
          >
          </el-avatar>
          <div class="rightPosition">
            <div class="rtop">
              <p>
                <el-avatar :size="55" :src="userInfo.headimg || circleUrl" />
                <span>{{ userInfo.nickname }}</span>
                <!-- <span>{{ JSON.parse(localStorage.getItem('userInfomm')).nickname || 'user'}}</span> -->
              </p>
              <p @click="logout">退出登录</p>
            </div>
            <div class="avatarVip">
              <div class="aDengji">
                <!-- <span class="lvd" :style="{ backgroundColor: userInfo.vip.color }">Lv.{{ userInfo.vip.leve }}</span> -->
                <img src='@/assets/vips/zb.png' class="imgText" v-if="userInfo.vip.leve == '主播'"/>
                <img src='@/assets/vips/zl.png' class="imgText" v-else-if="userInfo.vip.leve == '助理'"/>
                <img src='@/assets/vips/xx.png' class="imgText" v-else-if="userInfo.vip.leve == '1'"/>
                <img src='@/assets/vips/2.png' class="imgNumber" v-else-if="userInfo.vip.leve == '2'">
                <img src='@/assets/vips/3.png' class="imgNumber" v-else-if="userInfo.vip.leve == '3'"/>
                <img src='@/assets/vips/4.png' class="imgNumber" v-else-if="userInfo.vip.leve == '4'"/>
                <img src='@/assets/vips/5.png' class="imgNumber" v-else-if="userInfo.vip.leve == '5'"/>
                <img src='@/assets/vips/6.png' class="imgNumber" v-else-if="userInfo.vip.leve == '6'"/>
                <img src='@/assets/vips/7.png' class="imgNumber" v-else-if="userInfo.vip.leve == '7'"/>
                <img src='@/assets/vips/8.png' class="imgNumber" v-else-if="userInfo.vip.leve == '8'"/>
                <el-progress :percentage="userInfo.vip.num || 0" color="#ffbe32">
                  <span text style="font-size: 12px">{{ userInfo.vip.num }} / 100</span>
                </el-progress>
              </div>
              <div class="dbottom">
                <div class="dText">
                  <!-- <div class=da1>Lv.{{ userInfo.vip.leve }}</div> -->
                  <div class='da1' v-if="userInfo.vip.leve == '主播'">主播</div>
                  <div class='da1' v-else-if="userInfo.vip.leve == '助理'">助理</div>
                  <div class='da1' v-else-if="userInfo.vip.leve == '1'">新秀</div>
                  <div class='da1' v-else-if="userInfo.vip.leve == '2'">青铜</div>
                  <div class='da1' v-else-if="userInfo.vip.leve == '3'">白银</div>
                  <div class='da1' v-else-if="userInfo.vip.leve == '4'">黄金</div>
                  <div class='da1' v-else-if="userInfo.vip.leve == '5'">铂金</div>
                  <div class='da1' v-else-if="userInfo.vip.leve == '6'">钻石</div>
                  <div class='da1' v-else-if="userInfo.vip.leve == '7'">大师</div>
                  <div class='da1' v-else-if="userInfo.vip.leve == '8'">王者</div>
                  <div class=da2>等级</div>
                </div>
                <div class="center"></div>
                <div class="dText">
                  <div class=da1>{{ userInfo.vip.num }}</div>
                  <div class=da2>经验</div>
                </div>
              </div>
            </div>
            <div class="avatarRow">
              <el-row>
                <el-col :span="6">
                  <div class="abox" @click="skipRouteruserinfo(0)">
                    <img src="@/assets/images2/gern.png" alt="">
                    <p>个人中心</p>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="abox" @click="skipRouteruserinfo(1)">
                    <img src="@/assets/images2/gern2.png" alt="">
                    <p>我的关注</p>
                  </div></el-col>
                <el-col :span="6"></el-col>
                <el-col :span="6"></el-col>
              </el-row>
            </div>
          </div>
        </div>
          </div>



        </div>
      </div>
    </div>

    <!-- 登录 -->
    <el-dialog
      v-model="dialogVisibleLogin"
      width="400px"
      @close="closeDialogOne('')"
    >
      <div class="dialogVisibleLogin">
        <div class="loginTitle">登录</div>

        <!-- <vue-tel-input v-model="phone" mode="international"></vue-tel-input> -->
        <el-form :model="form" :rules="rulesForm2" ref="ruleFormRef2">
          <div class="nameInput">
            <div class="name" @click="wayCodeListBoolean">{{ area }}</div>
            <div class="country-code-list" v-if="codeListBoolean">
              <ul>
                <div v-for="(item, index) in areaCode" :key="index">
                  <li class="country-code-title" v-if="item.iden">
                    <h4>{{ item.prefix }}</h4>
                  </li>
                  <li
                    class="country-code-item"
                    v-if="!item.iden"
                    @click="clickAreaCode(item.prefix)"
                  >
                    <span class="left">{{ item.cn }}</span
                    ><span class="right">{{ item.prefix }}</span>
                  </li>
                </div>
              </ul>
            </div>
            <el-form-item prop="phone">
              <el-input
                v-model="form.phone"
                :maxlength="11"
                placeholder="请输入您的手机号"
              />
            </el-form-item>
          </div>
          <div class="nameInput2">
            <el-form-item prop="password">
              <el-input
                v-model="form.password"
                placeholder="请输入6-16位字母或数字密码"
                show-password
              />
            </el-form-item>
          </div>
          <div class="checkboxText">
            <el-form-item>
              <el-checkbox v-model="checked1" size="small"
                >登录即代表你已同意<span
                  style="color: red"
                  @click="openAgreement"
                  >《用户协议服务》</span
                ></el-checkbox
              >
            </el-form-item>
            <span class="p" @click="routerPassword">忘记密码</span>
          </div>
          <div class="btnLogin">
            <div @click="wayswitch('注册')">注册新账号</div>
            <el-button
              type="danger"
              class="submit"
              :disabled="!checked1"
              @click="submitLogin"
              >登录</el-button
            >
          </div>
        </el-form>
        <img src="@/assets/images2/Rectangle.png" alt="" class="imgFindx" />
      </div>
    </el-dialog>

    <!-- 注册 -->
    <el-dialog
      v-model="dialogVisibleRegister"
      width="400px"
      @close="closeDialogOne('register')"
    >
      <div class="dialogVisibleLogin">
        <div class="loginTitle">注册</div>
        <el-form :model="formData" :rules="rulesForm" ref="ruleFormRef">
          <div class="nameInput">
            <div class="name" @click="wayCodeListBoolean">{{ area }}</div>
            <div class="country-code-list" v-if="codeListBoolean">
              <ul>
                <div v-for="(item, index) in areaCode" :key="index">
                  <li class="country-code-title" v-if="item.iden">
                    <h4>{{ item.prefix }}</h4>
                  </li>
                  <li
                    class="country-code-item"
                    v-if="!item.iden"
                    @click="clickAreaCode(item.prefix)"
                  >
                    <span class="left">{{ item.cn }}</span
                    ><span class="right">{{ item.prefix }}</span>
                  </li>
                </div>
              </ul>
            </div>
            <el-form-item prop="phone">
              <el-input
                v-model="formData.phone"
                :maxlength="11"
                placeholder="请输入您的手机号"
              />
            </el-form-item>
          </div>
          <div class="phoneCode">
            <el-form-item prop="sms_code">
              <el-input
                v-model="formData.sms_code"
                placeholder="获取并输入验证码"
              />
            </el-form-item>
            <div class="code">
              <span v-if="show" @click="waygetCode(formData.phone)"
                >获取验证码</span
              >
              <span v-else>{{ timeCount }}</span>
            </div>
          </div>
          <div class="userName">
            <el-form-item prop="nickname">
              <el-input
                v-model="formData.nickname"
                placeholder="请输入用户名"
              />
            </el-form-item>
          </div>
          <div class="userName">
            <el-form-item prop="password">
              <el-input
                v-model="formData.password"
                placeholder="请输入6-16位字母和数字密码"
                show-password
              />
            </el-form-item>
          </div>
          <div class="codeText">
            <el-form-item prop="sidentifyMode">
              <el-input
                v-model="formData.sidentifyMode"
                placeholder="请输入图片上的验证码"
              />
            </el-form-item>
            <div class="code" @click="refreshCode">
              <SIdentify :identifyCode="identifyCode"></SIdentify>
            </div>
          </div>
          <div class="checkboxText">
            <el-form-item>
              <el-checkbox v-model="checked1" size="small"
                >登录即代表你已同意<span
                  style="color: red"
                  @click="openAgreement"
                  >《用户协议服务》</span
                ></el-checkbox
              >
            </el-form-item>
            <span class="p" @click="routerPassword">忘记密码</span>
          </div>
          <div class="btnLogin">
            <div @click="wayswitch('登录')">登录账号</div>
            <el-button
              type="danger"
              class="submit"
              @click="register"
              :disabled="!checked1"
              >注册</el-button
            >
          </div>
        </el-form>
        <img src="@/assets/images2/Rectangle.png" alt="" class="imgFindx" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  ref,
  onMounted,
  onCreated,
  watch,
  computed,
  nextTick,
} from "vue";
import { Search } from "@element-plus/icons-vue";
import {
  GetSmsCode,
  GetCountryPhonePrefix,
  UserLoginUserEnroll,
  UserLoginUserLogin,
  loginRecord,
  getNowLiveAnchors,
  getVideoLogoMsg,
  LeNewdownloadPath,
} from "@/axios/api";
import { ElMessage } from "element-plus";
import SIdentify from "@/components/Sidentify.vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import jigouZim from "@/until/index.js";
import { exportGetBrowserId } from "@/until/upmethods.js";
import {eventBus} from "@/until/onBus"
export default {
  components: {
    SIdentify,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const state = reactive({
      count: computed(() => store.state.count),
      userInfomm_gunzhu: computed(() => store.state.userInfomm_gunzhu),
      likeObjvuex: computed(() => store.state.likeObjvuex),
      circleUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      dialogVisibleLogin: false, //登录弹窗
      dialogVisibleRegister: false, //注册弹窗
      search:'',
      form: {
        //登录c
        phone: "",
        password: "",
      },
      formData: {
        //注册
        phone: "",
        password: "",
        nickname: "",
        sms_code: "",
        sidentifyMode: "", //图片验证码
      },
      checked1: false, //是否阅读协议
      timeCount: 60, //设置验证码60s初始值
      show: true,
      areaCode: [], //地区码
      area: "+86",
      codeListBoolean: false,
      userInfo: computed(() => store.state.userInfomm), //用户信息
      cookieID: "",
      userInfoAds: {},
      darkMode: false
    });
    const ruleFormRef = ref(); //注册表单的refs属性
    const ruleFormRef2 = ref(); //登录表单的refs属性

    //监听store数据里面的变化调用登录
    watch(
      () => state.count,
      () => {
        state.dialogVisibleLogin = true;
      }
    );
    //打开区域码弹窗
    const wayCodeListBoolean = () => {
      state.codeListBoolean = !state.codeListBoolean;
    };
    //选择地区
    const clickAreaCode = (v) => {
      state.codeListBoolean = false;
      state.area = v;
    };
    //注册Dialog关闭时回调
    const closeDialogOne = (e) => {
      if (e == "register") {
        ruleFormRef.value.resetFields();
      } else {
        ruleFormRef2.value.resetFields();
      }
      state.codeListBoolean = false;
      state.checked1 = false;
      state.area = "+86";
    };
    //获取短信验证码
    const wayGetSmsCode = (phone) => {
      GetSmsCode({
        phone,
      })
        .then((res) => {
          ElMessage({ type: "success", message: res.msg });
        })
        .catch((err) => {
          ElMessage({ type: "error", message: err.msg });
        });
    };
    //获取地区手机码
    const wayGetCountryPhonePrefix = () => {
      GetCountryPhonePrefix()
        .then((res) => {
          let data = res.data;
          const convertedData = [];

          for (const key in data) {
            convertedData.push({ prefix: key, cn: "", iden: true });
            data[key].forEach((item) => {
              item.iden = false;
              convertedData.push(item);
            });
          }
          state.areaCode = convertedData;
        })
        .catch((err) => {});
    };
    //跳转到用户详情
    const skipRouteInfo = () => {
      router.push("/userinfo");
    };
    const searchClock = ()=>{
      if(route.name!=='selor'){
        router.push({ name: 'selor', query: { search: state.search } });
      }else{
        // store.state.search( state.search)
        eventBus.emit('search', state.search);
        // console.log(store.state.search,'state')

        // store.commit("searchF",state.search);


        // store.commit("darkModeFT", EV);
        router.replace({ name: 'selor', query: { search: state.search } });
      }
    };
    // 切换区域 登录/注册
    const wayswitch = (e) => {
      if (e == "注册") {
        state.dialogVisibleLogin = false;
        state.dialogVisibleRegister = true;
      } else {
        state.dialogVisibleLogin = true;
        state.dialogVisibleRegister = false;
      }
    };
    // 注册区域---------
    // 设置按钮点击后禁用
    const dis = ref(false);
    const countDown = ref();
    const waygetCode = (ve) => {
      // 设置正则表达式————手机号判断
      let m =
        /^(?:(?:\+|00)86)?1(?:(?:3\d)|(?:4[5-9])|(?:5[0-35-9])|(?:6[2567])|(?:7[0-8])|(?:8\d)|(?:9[189]))\d{8}$/.test(
          state.formData.phone
        );
      if (!state.formData.phone) {
        setTimeout(() => {
          ElMessage({
            message: "手机号不能为空",
            type: "error",
          });
        }, 200);
      } else if (state.formData.phone.length !== 11) {
        setTimeout(() => {
          ElMessage.error("手机格式错误，请重新输入");
        }, 200);
      } else if (m) {
        wayGetSmsCode(ve);
        // setInterval()函数实现定时调用，1000为毫秒也就是1s
        countDown.value = setInterval(() => {
          setTimeout(() => {
            if (state.timeCount > 0 && state.timeCount <= 60) {
              dis.value = true;
              state.show = false;
              state.timeCount--;
            } else {
              state.show = true;
              // 取消setInterval()函数设定的定时执行操作
              clearInterval(state.timeCount);
            }
          }, 0);
        }, 1000);
      } else if (!m) {
        setTimeout(() => {
          ElMessage.error("手机格式错误，请重新输入");
        }, 200);
      }
    };
    //生成随机字符串
    function generateRandomString() {
      var result = "";
      var characters = "0123456789abcdefghijklmnopqrstuvwxyz";
      for (var i = 0; i < 4; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
      return result;
    }

    //设置图片验证码
    let identifyCode = ref(""); //图形验证码
    let identifyCodes = ref("1234567890abcdefjhijklinopqrsduvwxyz"); //验证码出现的数字和字母
    const randomNum = (min, max) => {
      max = max + 1;
      return Math.floor(Math.random() * (max - min) + min);
    };
    // 随机生成验证码字符串
    const makeCode = (o, l) => {
      for (let i = 0; i < l; i++) {
        identifyCode.value += o[randomNum(0, o.length)];
      }
    };
    // 更新图片验证码
    const refreshCode = () => {
      identifyCode.value = "";
      // makeCode(identifyCodes.value, 4);
      identifyCode.value = generateRandomString();
    };
    // 手机号码校验函数
    const checkPhoneNum = (rule, value, callback) => {
      var reg = /^1[3456789]\d{9}$/;
      if (reg.test(value)) {
        callback();
      } else {
        callback(new Error("手机号码格式不正确"));
      }
    };
    //用户名校验
    const validateInput = (rule, value, callback) => {
      const regExp = /^[\u4e00-\u9fa5a-zA-Z0-9]{1,10}$/; // 正则表达式
      if (value && !regExp.test(value)) {
        // 校验失败
        callback(new Error("只能输入数字、字母、汉字，长度为1到10位"));
      } else {
        callback(); // 校验成功
      }
    };
    // 注册表单校验 -- 注册
    const rulesForm = reactive({
      // 手机号码校验规则
      phone: [
        { required: true, message: "请输入手机号码", trigger: "blur" },
        { validator: checkPhoneNum, trigger: "blur" },
      ],
      sms_code: [
        { required: true, message: "请输入数字验证码", trigger: "blur" },
        { pattern: /^\d{6}$/, message: "请输入六位数字", trigger: "blur" },
      ],
      nickname: [
        { required: true, message: "请输入用户名", trigger: "blur" },
        {
          validator: validateInput,
          message: "只能输入数字、字母、汉字，长度为1到10位",
          trigger: "blur",
        },
      ],
      password: [
        { required: true, message: "请输入数字密码", trigger: "blur" },
        // { pattern: /^\d{6}$/, message: "请输入六位数字密码", trigger: "blur" },
        {
          min: 6,
          max: 16,
          message: "密码长度应为6到16个字符",
          trigger: "blur",
        },
        {
          pattern: /^[0-9a-zA-Z]{6,16}$/,
          message: "密码应包含字母和数字,不含特殊字符",
          trigger: "blur",
        },
      ],
      sidentifyMode: [
        { required: true, message: "请输入图片上的验证码", trigger: "blur" },
        { min: 4, max: 4, message: "验证码长度为4位", trigger: "blur" },
      ],
    });
    // 表单校验 -- 登录
    const rulesForm2 = reactive({
      phone: [
        { required: true, message: "请输入手机号码", trigger: "blur" },
        { validator: checkPhoneNum, trigger: "blur" },
      ],
      password: [
        { required: true, message: "请输入数字密码", trigger: "blur" },
        // { pattern: /^\d{6}$/, message: "请输入六位数字密码", trigger: "blur" },
        {
          min: 6,
          max: 16,
          message: "密码长度应为6到16个字符",
          trigger: "blur",
        },
      ],
    });
    //跳转到用户协议
    const openAgreement = () => {
      // const routeUrl = "/agreement"; // 新窗口要跳转的路由路径
      // const newWindow = window.open("", "_blank");
      // if (newWindow) {
      //   newWindow.location.href = routeUrl;
      // }
      state.dialogVisibleLogin = false;
      state.dialogVisibleRegister = false;
      router.push("/agreement");
    };
    //注册 -校验
    const registerSubmit = async (formEl, v2) => {
      const valid = await formEl.validate((valid, fields) => {
        if (valid) {
          return true;
        } else {
          return false;
        }
      });
      return valid;
    };
    //请求注册登录接口
    const register = async () => {
      if (identifyCode.value != state.formData.sidentifyMode)
        return ElMessage({ type: "error", message: "图片验证码错误" });
      const bool = await registerSubmit(ruleFormRef.value, true);
      if (!bool) return;
      const params = {
        phone: state.formData.phone,
        sms_code: state.formData.sms_code,
        nickname: state.formData.nickname,
        password: state.formData.password,
      };
      //注册接口
      UserLoginUserEnroll(params)
        .then((res) => {
          ElMessage({ type: "success", message: res.msg });
          state.dialogVisibleRegister = false;
          state.dialogVisibleLogin = true;
        })
        .catch((err) => {
          ElMessage({ type: "error", message: err.msg });
        });
    };
    //登录
    const submitLogin = async (formEl) => {
      const bool = await registerSubmit(ruleFormRef2.value, false);
      if (!bool) return;
      let params = {
        phone: state.form.phone,
        password: state.form.password,
      };
      UserLoginUserLogin(params)
        .then((res) => {
          ElMessage({ type: "success", message: res.msg });
          window.localStorage.setItem("userInfomm", JSON.stringify(res.data));
          store.commit("addUserInfomm", res.data);
          store.commit("addVuservuex", [res.data.id, res.data.nickname]);
          state.dialogVisibleLogin = false;
          jigouZim.jiglogout();
          setTimeout(() => {
            jigouZim.loginZim();
          }, 1000);
          if (route.name == "liveInfo") {
            location.reload();
          }
        })
        .catch((err) => {
          ElMessage({ type: "error", message: err.msg });
        });
    };
    //退出登录
    const logout = () => {
      window.localStorage.removeItem("userInfomm");
      ElMessage({ type: "success", message: "账号已退出" });
      // state.userInfo = {}
      store.commit("removeUserInfomm");
      store.commit("addVuservuex", ["", ""]);
      jigouZim.jiglogout();
      setTimeout(() => {
        jigouZim.loginZim();
      }, 1000);
      location.reload();
    };
    //跳转到忘记密码
    const routerPassword = () => {
      router.push("/editpassword");
      state.dialogVisibleRegister = false;
      state.dialogVisibleLogin = false;
    };
    const spikRouter = (url) => {
      if (url == route.path) return window.location.reload();
      router.push(url);
      wayloginRecord();
    };
    //记录用户活跃时间
    const wayloginRecord = () => {
      loginRecord({
        uid: store.state.userInfomm.id || "",
        cid: state.cookieID,
        type: "1",
      })
        .then((res) => {})
        .catch((err) => {});
    };
    //获取关注 正在直播的主播
    const waygetNowLiveAnchors = () => {
      if (store.state.vuexIslogin == 0) return;
      getNowLiveAnchors({
        uid: store.state.userInfomm.id,
      })
        .then((res) => {
          store.commit("addGunzhuAnotor", { type: "add", data: res.data });
        })
        .catch((err) => {});
    };
    const skipRouteruserinfo = (type) => {
      router.push({
        path: "/userinfo",
        query: {
          type: type || 0,
        },
      });
    };
    const skipRouterLiveInfo = (id) => {
      router.push({
        path: "/liveInfo",
        query: {
          id,
          type: "live",
        },
      });
    };
    // 获取系统消息
    const wayGetVideoLogoMsg = () => {
      getVideoLogoMsg()
        .then((res) => {
          if (res.data.ads.ads.length > 0) {
            state.userInfoAds = res.data.ads.ads[0];
          }
        })
        .catch((err) => {});
    };
    //触发点击首页图标按钮
    const wayHomeRouter = () => {
      // 浏览器刷新页面
      window.location.reload();
    };
    // 获取系统消息
    const wayLeNewdownloadPath = () => {
      LeNewdownloadPath()
        .then((res) => {
          store.commit("systemImgelike", res.data);

          document.styleSheets[0].insertRule(
            `#heraderDiv::after { background-image: url("${res.data.iosImgPath}")}`,
            0
          );
        })
        .catch((err) => {});
    };
    const changeChecked1 = (EV) => {
      store.commit("darkModeFT", EV);
      const element = document.querySelector('.header')
      if(EV){
        element.style.backgroundColor = "rgba(19, 19, 19, 0.805)"
      }else{
        element.style.backgroundColor = "#f6f6f6c8"
      }
    };
    // 监听滑动距离顶部
    const handleResize = ()=>{
      window.addEventListener('scroll', () => {
        const scrollTop = window.scrollY || document.documentElement.scrollTop;
        const element = document.querySelector('.header')
        if(!state.darkMode){
          if(scrollTop > 10){
            element.style.backgroundColor = 'hsla(0,0%,85%,.9)';
          }else{
            element.style.backgroundColor = '#f6f6f6c8'
          }
          // 更改背景颜色
        }else{
          if(scrollTop > 10){
            element.style.backgroundColor = 'rgba(19, 19, 19, 0.759)';

          }else{
            element.style.backgroundColor = 'rgba(19, 19, 19, 0.06)'
          }
        }
      });
    }
    onMounted(() => {
      identifyCode.value = "";
      state.darkMode = store.state.darkMode
      handleResize()
      makeCode(identifyCodes.value, 4);
      wayGetCountryPhonePrefix();
      waygetNowLiveAnchors();
      wayGetVideoLogoMsg();
      state.cookieID = exportGetBrowserId();
      wayloginRecord();
      wayLeNewdownloadPath();
    });
    return {
      ...toRefs(state),
      changeChecked1,
      spikRouter,
      refreshCode,
      routerPassword,
      makeCode,
      randomNum,
      waygetCode,
      wayswitch,
      wayHomeRouter,
      skipRouteInfo,
      wayCodeListBoolean,
      clickAreaCode,
      closeDialogOne,
      openAgreement,
      registerSubmit,
      register,
      submitLogin,
      logout,
      skipRouteruserinfo,
      skipRouterLiveInfo,
      identifyCode,
      identifyCodes,
      rulesForm,
      rulesForm2,
      ruleFormRef2,
      ruleFormRef,
      Search,
      searchClock
    };
  },
};
</script>

<style lang="scss" scoped>


::v-deep .headerTopFlex .el-input__wrapper{
  box-shadow: 0 0 0 1px #dcdfe6 inset!important;
  //box-shadow: 0 0 0 1px #4c4d4f, #4c4d4f inste;
}
@media(max-width: 1500px) {
  .header{
    .headerCenter{
      .imglogo{
        width:220px !important
      }
    } 
  }
 
 
}
@mixin ElementInputBotton {
  :deep(.el-input__wrapper) {
    padding: 1px 0px;
  }
  :deep(.el-form-item) {
    width: 100%;
    margin-bottom: 0;
  }
}
:deep(.el-form-item.is-error .el-input__wrapper) {
  box-shadow: none;
}

.header {
  position: sticky;
  top: 0px;
  z-index: 99;
  background-color: #f6f6f6c8;
  // background-color:rgba(245, 245, 245,0.7);

  .headerTopFlex {
    font-weight: 400;
    .headerCenter {
      display: flex;
      justify-content: space-between;
      z-index: 120;
      align-items: center;
      width: 100%;
      height: 80px;
      .imglogo {
        width: 240px;
        height: 100%;
        display: flex;

        .nav-top-conten-right {
          display: flex;
          align-items: center;
          cursor: pointer;
          overflow: hidden;
          flex: 1;
          min-width: 0;
          padding-left: 30px;

          a {
            width: 138px;
            height: 40px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }

      .headerContant {

        height: 100%;
        flex: 1;
        display: flex;
        align-items: center;
        #heraderDiv{
          position: relative;
          .hotImges{
            position: absolute;
            width: 25px;
            top: -10px;
            left: 70%;
            margin-right: 10px;
          }
        }

        .selor {
          width: 35%;
          min-width: 450px;
          display: flex;
          align-items: center;
          .selorInputOne{
              width: 370px; height: 40px !important
          }
          .selorInputTwo{
              width: 403px; height: 40px !important
          }
          :deep(.el-input__wrapper) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            padding: 1px 15px;
            font-size: 14px;
          }

          .selor-right {
            width: 80px;
            height: 40px;
            display: flex;
            cursor: pointer;
            align-items: center;
            justify-content: center;
            box-shadow: 0 0 0 1px #dcdfe6 inset!important;
            background: #ebebeb;
            border-left: 0 !important;
            margin-left: -1px;
            img {
              width: 22px;
              margin-right:8px
            }
          }
        }
        .heraderNav {

          display: flex;
          margin-right: 30px;
          width: 38%;
          a{
            text-decoration:none;
            color:#898989;
          }
          .active{

            font-size: 18px !important;
            font-weight: 700;
            a{
              color: #000 ;
            }
          }
          .div {
            font-size: 16px;
            flex: 1;
            min-width: 40px;
            text-align: center;
            cursor: pointer;
            transition: all 0.2s;
            line-height: 30px;
          }
          .div:hover{
            font-weight: 700;
            font-size: 18px;
            a{
              color: #000;
            }
          }
        }
      }
      .userContre2{
        position: absolute;
        right: 11.5% !important;
        margin-right: 0 !important;
      }
      .userContre{
        font-weight:400;
        display:flex;
        align-items: center;
        margin-right: 30px;
        .user{
          .avatar {
        position: relative;
        &:hover .rightPosition {
          visibility: visible;
        }
        .avatarCss {
          float: right;
        }
        .rightPosition {
          visibility: hidden;
          position: absolute;
          right: -10px;
          top: 45px;
          width: 330px;
          background: #ffffff;
          box-shadow: 4px 4px 10px 0px rgba(181, 181, 181, 0.25);
          border-radius: 5px 5px 5px 5px;
          opacity: 1;
          z-index: 100;
          .avatarRow{
            padding: 10px 20px;
            .abox{
              cursor:pointer;
              color:#333333;
              font-size: 14px;
              text-align: center;
              img{
                width: 30px;
                height:30px;
              }
            }
          }
          .avatarVip {
            // width: 630px;
            width:100%;
            margin: 10px auto;
            padding-bottom:10px;
            border-bottom: 1px solid rgb(232, 232, 232);
            .dbottom {
              // width: 380px;
              padding:0 20px;
              // margin: 20px auto 0;
              display: flex;
              align-items: center;
              justify-content: space-around;
              .dText {
                color:#333;
                text-align: center;
                .da1{
                  font-size: 14px;
                  font-weight: 600;
                }
                .da2{
                  font-size: 12px;
                  color: #777;
                }
              }
              .center {
                background-color: #ddd;
                height: 40px;
                width: 1px;
              }
            }
            .aDengji {
              padding:0 20px;
              display: flex;
              // align-items: flex-start;
              justify-content: center;
              .imgText{
                width:45px;
                height: 22px;
                // max-width: 35px;
                margin-right: 5px;
                padding-bottom: 5px; 
                // transform: scale(1.2);
              }
              .imgNumber{ 
                padding-bottom: 6px; 
                margin-right: 5px;
                height: 20px;
                  max-width: 35px;
                  transform: scale(1.2);
              }
              .lvd {
                color: #fff;
                border-radius: 4px;
                font-size: 10px;
                margin-right: 10px;
                padding: 0 6px;
                height: 16px;
              }
              .el-progress--line {
                margin-bottom: 15px;
                width: 350px;
                height: 15px;
              }
            }
          }
          .rtop {
            padding: 20px 0 0px;
            color: #000;
            display: flex;
            align-items: center;
            justify-content: space-between;
            p:nth-child(1) {
              display: flex;
              align-items: center;
              margin-left: 15px;
              font-size: 17px;
              span {
                max-width: 140px;
                margin-left: 10px;
                white-space: nowrap; //不换行
                overflow: hidden; //超出隐藏
                text-overflow: ellipsis;
              }
            }
            p:nth-child(2) {
              display: inline-block;
              border-radius: 2px;
              font-size: 14px;
              padding: 4px 10px;
              cursor: pointer;
              color: #828282;
              margin-right: 15px;
              border: 1px solid #bcbcbc;
            }
          }
        }
      }

          .no-user{
            width:84px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px 12px;
            border: 1px solid #FF5756;
            border-radius: 18px;
            font-size: 15px;
            color:#FF5756;
            cursor: pointer;
            img{
              width: 20px;
              margin-right: 5px;
            }
          }
        }
        .stydarke{
          display: flex;
          align-items: center;
          margin: 0 10px;
          margin-right: 20px;
          img{
            width: 30px;
          }
          .fontss{
            color:#222;
            margin: 0 5px;
            font-size:15px;
          }
        }
      }
    }
  }
}
.dark{
  background-color: rgba(19, 19, 19, 0.06);
  // background-color: rgba(19, 19, 19,0.7);

  .userContre{
    .fontss{
      color:#fff !important;
    }
    .user{


      .no-user{
        border:1px solid #fff !important;
        color:#fff !important
      }
    }
  }
  .headerContant{
    .selor{

      :deep(.el-input__wrapper) {
        background-color:transparent !important;

      }
      .selor-right{
        //border: 1px solid #d7dae2 !important;
        background: rgba(33, 33, 33, .6) !important;
      }
    }
    .heraderNav{
      .active{
        a{
          color: #fff !important;
        }
      }
      .div:hover{
        a{
          color: #fff !important;
        }


      }

    }
  }
}


.dialogVisibleLogin {
  margin-top: -20px;
  padding: 0 30px;
  position: relative;
  .imgFindx {
    width: 30px;
    height: 30px;
    position: absolute;
    left: -20px;
    top: -40px;
  }
  .codeText {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e9e9e9;
    margin-top: 15px;
    @include ElementInputBotton;
  }
  .userName {
    border-bottom: 1px solid #e9e9e9;
    margin-top: 30px;
    @include ElementInputBotton;
  }
  .phoneCode {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e9e9e9;
    margin-top: 30px;
    @include ElementInputBotton;
    .code {
      width: 71px;
      text-align: center;
      cursor: pointer;
      flex-shrink: 0;
    }
  }
  .btnLogin {
    width: 100%;
    padding: 30px 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    div:nth-child(1) {
      cursor: pointer;
      text-decoration: underline;
    }
    .submit {
      height: 36px;
      width: 106px;
    }
  }
  .checkboxText {
    margin: 10px 0 30px;
    display: flex;
    justify-content: space-between;
    :deep(.el-form-item__content) {
      justify-content: space-between;
    }
    .p {
      padding-top: 2px;
      font-size: 14px;
      cursor: pointer;
    }
  }
  .nameInput2 {
    border-bottom: 1px solid #e9e9e9;
    margin-top: 30px;
    @include ElementInputBotton;
  }
  .nameInput {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e9e9e9;
    position: relative;
    .country-code-list {
      // display: none;
      position: absolute;
      top: 34px;
      left: 0;
      width: 100%;
      height: 205px;
      z-index: 103;
      background: #fff;
      overflow-y: auto;
      box-shadow: 0 3px 10px 0 rgba(43, 58, 73, 0.1);
      ul {
        font-size: 14px;
        cursor: pointer;
        .country-code-title {
          cursor: default;
          background: #fff;
        }
        li {
          height: 34px;
          line-height: 34px;
          padding: 0 20px;
          .left {
            float: left;
          }
          .right {
            float: right;
          }
        }
        .country-code-item:hover {
          background: #f0f0f0;
        }
      }
    }
    .name {
      flex-shrink: 0;
      cursor: pointer;
    }
    .name::after {
      content: "";
      display: inline-block;
      width: 1px;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 5px solid #ffb600;
      margin-left: 1px;
      margin-right: 8px;
      vertical-align: middle;
    }
    :deep(.el-form-item) {
      width: 100%;
      margin-bottom: 0;
    }
  }
  .loginTitle {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 26px;
  }
  :deep(.el-input__wrapper) {
    box-shadow: none;
  }
  :deep(.el-message) {
    z-index: 99999;
  }
  :deep(.el-input__inner) {
    font-size: 14px;
  }
}
</style>
<style>
.night .header .headerTopFlex .headerCenter .headerContant .selor .selor-right{
  box-shadow: 0 0 0 1px #4c4d4f inset!important;

}

.night  .headerTopFlex .el-input__wrapper{
  box-shadow: 0 0 0 1px #4c4d4f inset!important;
//box-shadow: 0 0 0 1px #4c4d4f, #4c4d4f inste;
}
.headerTopFlex .el-input__wrapper:hover{
  box-shadow: 0 0 0 1px #c0c4cc inset!important;
//box-shadow: 0 0 0 1px #4c4d4f, #4c4d4f inste;
}
.night .headerTopFlex .el-input__wrapper:hover{
  box-shadow: 0 0 0 1px #6c6e72 inset!important;
//box-shadow: 0 0 0 1px #4c4d4f, #4c4d4f inste;
}
.header .headerTopFlex .headerCenter .headerContant .selor .is-focus{
  box-shadow: 0 0 0 1px #000 inset!important;
//box-shadow: 0 0 0 1px #4c4d4f, #4c4d4f inste;
}
.header .headerTopFlex .headerCenter .headerContant .selor .is-focus{
  box-shadow: 0 0 0 1px #4c4d4f inset!important;
//box-shadow: 0 0 0 1px #4c4d4f, #4c4d4f inste;
}
.night .selor .el-input__inner{
  color: #fff;
}
.night .el-dialog__header{
  background-color: #202020;
  /*padding: 20px 0;*/
  margin-right:0;
}
.night .el-dialog__body{
  background-color: #202020;

}
.night .el-input__wrapper{
  background-color: #202020;

}
.night .dialogVisibleLogin .nameInput .name::after{
  border-top: 5px solid #ff5756;
}
.night .dialogVisibleLogin .loginTitle{
  color: #fff;
}
.night .dialogVisibleLogin .nameInput .name{
  color: #fff;
}
.night .dialogVisibleLogin .el-input__inner{
  color: #fff;
}

.night .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #202020;
  border-color: #fff;
}
.night .el-checkbox__input.is-checked+.el-checkbox__label{
  color: #fff;

}
.dialogVisibleLogin .checkboxText .p{
  color: #fff;

}
.night .dialogVisibleLogin .btnLogin div:nth-child(1){
  color: #fff;

}
.night .el-button.is-disabled{
  background-color: #efefef;
  color: #999;
}
.night .el-button.is-disabled:hover{
  background-color: #fff;
  color: #999;
}
.night .dialogVisibleLogin .phoneCode .code{
  color: #fff;

}
</style>
