<template>
   <div class="scroll-container">
    <div class="videcons-gg-left">公告</div>
    <div class="scroll-text">
      {{ props.banner_mess }}
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
const props = defineProps(['banner_mess'])

</script>

<style scoped>
.scroll-container {
  
  width: 100%;
  height: 100%;
  /* width: 100px; */
  /* height: 50px; */
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.videcons-gg-left{
  width: 82px;
    height: 33px;
    color: #fff;
    text-align: center;
    line-height: 32px;
    font-size: 15px;
    font-weight: 400;
    padding: 0 11px;
    z-index:9;
    background-color: #1d74ff;
    font-family: Dream-BraveGB Heavy-Regular, Dream-BraveGB Heavy;
}

.scroll-text {
  display: inline-block;
  padding-left: 100%;
  animation: scroll 45s linear infinite;
  color: #202020;
  margin-left: 82px;
  font-size: 14px;
  line-height: 30px;
  cursor: pointer;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
</style>