<template>
  <div class="footer" :class="darkMode ? 'darkFooter' : ''">
    <div class="copytext">猛犸体育所有直播信号均由用户收集或从搜索引擎搜索整理获得，所有内容均来自互联网，我们自身不提供任何直播信号和视频内容 
      <br/>如有侵犯您的权益请通知我们，我们会第一时间处理。</div>
    <div class="copytext" style="padding-top: 12px;">猛犸体育是一家为体育IP方提供立项、运营、推广、销售、执行等“全生命周期”策略服务的公司，24小时为广大球迷提供全面及时的赛事直播和资讯</div>
    <div class="copytext" >完全绿色安全无插件，稳定安全的直播网，每天收集最新的体育直播资讯，原创大数据足球篮球赛果预测，历史战绩，情报分析。 </div>
    <div class="copytext" style="padding-top: 12px;">Version: 2.5.0</div>
  </div>
</template>

<script setup>
    import { ref, onMounted, onUnmounted, computed } from 'vue'
    import { useStore } from 'vuex'
    const store = useStore()
    const darkMode = computed(() => store.state.darkMode)
</script>

<style lang="scss" scoped>

.footer {
  width: 100%;
  color: #222;
  text-align: center;
  background: #fff;
  padding: 50px 0 20px;
  .footerImg {
    height: 60px;
    padding-top: 10 px;
    margin: 0 auto;
    img {
      height: 100%;
    }
  }
  .footerText {
    padding: 10px 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    font-size: 14px;
    div:nth-child(2) {
      margin: 0 30px;
    }
  }
  .copytext {
    font-size: 12px;
  }
}
.darkFooter{
  color:#fff;
  background: #161719
}
</style>