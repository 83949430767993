<template>
  <div  :class="darkMode ? 'darkhomePage':''" class="homePage">
    <div class="homeTop">
      <div class="home-right-nav">
        <div>
          <div class="nabsin">
            <div class="nav">
              <ul class="nav-content">
                <li class="bgcole">
                  <img src="@/assets/infoicon/a.png" alt="" v-if="!darkMode">
                  <img src="@/assets/infoicon/a2.png" alt="" v-else>
                  <p>首页</p>
                </li>
                <li class="" @click="spikRouter('/saicheng')">
                  <img src="@/assets/infoicon/b.png" alt="">
                  <p>赛程</p>
                </li>
                <li class="" @click="spikRouter('/schedule')">
                  <img src="@/assets/infoicon/c.png" alt="">
                  <p>回放</p>
                </li>
                <li class="">
                  <a :href="userInfoAds.ad_url" target="_blank"  style="display: flex;align-items: center">
                  <img src="@/assets/infoicon/d.png" alt="">
                  <p>QXQ</p>
                  </a>
                </li>
                <li class="" @click="spikRouter('/appinstall')">
                  <img src="@/assets/infoicon/e.png" alt="">
                  <p class="navrings">
                    下载
                    <img class="hotImges" src="https://mengmazb.com/Public/icon/hot.svg"> 
                  </p>
             
                </li>
              </ul>
            </div>
           
          </div>
          <div class="upsplo-bos">
            <img src="@/assets/images/upaddbgfb1f77c.png" alt="" class="lohuuo-hongbao">
            <div class="lohuuo">
              <p>使用手机扫二维码下载猛犸直播和美女主播互动，与红单主播一起分析前沿赛事，掌上高清投屏手机端用户还有福利红包抢，快扫码下载吧～</p>
              <div class="erweima">
                <img :src="likeObjvuex.downloadImgPath" alt="">
              </div>
              <p>扫描二维码下载APP</p>
            </div>
          </div>
          <div class="navright"></div>
          <div class="msgright"></div>
        </div>
      </div>
      <div class="h-content">

        <div class="homeindx">
          <div class="zhibo">
            <div class="zhibo-left">
              <div class="zhibo-left-video">
                <div class="video"><div id="mse" style="width: 100%; height: 100%"></div></div>
                <div class="videcons-gg">
                  <div is="vue:scrollcontainer" :banner_mess="systemMessage.banner_mess"></div>
                </div>
              </div>
              <div class="welcome" v-if="liveingAnchor.length != 0" @click="skipRouteInfo">
                <p>进入直播间</p>
              </div>
              <div class="iconZanting" v-if="m3u81VideoIcon"></div>
            </div>
            <div class="zhibo-right">
              <div class="el-scrollbar">
                <div class="el-scrollbar__wrap el-scrollbar__wrap--hidden-default">
                  <div class="el-scrollbar__view">
                    <ul class="usndf">
                      <li v-for="(item, index) in liveingAnchor" :key="index" @click="wayiframeNav(index)">
                        <div class="el-image">
                          <img :src="item.live_cover" alt="" class="el-image__inner">
                        </div>
                        <div class="usndf-bg" :class="iframeNav == index ? 'usndfbg':''" ></div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="advertisement">
            <a :href="absObj.ad_url" target="_blank">
              <el-image style="width: 100%;" :src="absObj.ad_img" :fit="'fill'" />
              <p>广告</p>
            </a>
          </div>
          <div class="hot">
            <div class="title">
              <div class="title-p">今日有20场热门比赛</div>
              <div class="title-right lefys">
                <template v-if="!darkMode">
                  <img src="@/assets/images/blackLeft.png" alt="" @click="handscorllbarLeft">
                  <img src="@/assets/images/blackRight.png" alt="" @click="handscorllbarRight">
                </template>
                <template v-else>
                  <img src="@/assets/images/baileft.png" alt="" @click="handscorllbarLeft">
                  <img src="@/assets/images/bairight.png" alt="" @click="handscorllbarRight"> 
                </template>

              </div>
            </div>
            <el-scrollbar ref="scrollbarRef"  @scroll="scrollscrollbarRef">
              <div class="scrollbar-flex-content">
                <ul class="hot-conte" ref="scrollbarReful">
                  <li v-for="(item,index) in followingMatchs" :key="index" @click="skipRoute(item.id,'video', item.fire)">
                    <div class="top">
                      <span class="ovehide">{{item.league}}</span>
                      <div class="rmlogin" v-if="item.play1 > 2">
                        <img src="@/assets/images/live2.png" alt="">
                        <span>正在直播</span>
                      </div>
                    </div>
                    <div class="senb">
                      <div class="senb-cons">
                        <div class="senb-cons-img ovehide">
                          <el-image style="width: 30px; height: 30px" :src="item.a_logo" />
                          <p class="ovehide">
                            <span>{{item.a}}</span>
                          </p>
                        </div>
                      </div>
                      <div class="senb-bf" v-if="item.play1 == 2">
                        <span>-</span>
                        <span style="padding: 0px 5px">:</span>
                        <span>-</span>
                      </div>
                      <div class="senb-bf" v-if="item.play1 > 2">
                        <span>{{item.scoreAText}}</span>
                        <span style="padding: 0px 5px">:</span>
                        <span>{{ item.scoreBText }}</span>
                      </div>
                  
                      <div class="senb-cons">
                        <div class="senb-cons-img ovehide">
                          <el-image style="width: 30px; height: 30px" :src="item.b_logo" />
                          <p class="ovehide">{{item.b}}</p>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </el-scrollbar>
          </div>
          <div class="hot2">
            <div class="title"><p class="title-p">正在热播</p></div>
            <div class="dataList">
          <el-skeleton
            :loading="skeletonBool"
            animated
            :count="5"
            :throttle="500"
            style="display: flex; justify-content: space-between"
          >
            <template #template>
              <el-space direction="vertical" alignment="flex-start">
                <el-skeleton-item
                  variant="image"
                  style="width: 285px; height: 190px"
                />
                <div style="padding-top: 4px; width: 285px">
                  <el-skeleton-item variant="p" style="width: 50%" />
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      justify-items: space-between;
                    "
                  >
                    <el-skeleton-item
                      variant="text"
                      style="margin-right: 16px"
                    />
                    <el-skeleton-item variant="text" style="width: 30%" />
                  </div>
                </div>
              </el-space>
            </template>

            <template #default>
              <div
                class="recommend"
               
              >
              <div class="recommend-conten"  v-for="(item, index) in infoList"
                :key="index"
                @click="
                  skipRoute(item.id, item.type, item.fire, item.playBack_id)
                ">
                <div class="recommend-img">
                  <div class="el-image animaimg">
                    <img :src="item.live_cover" alt="">
                  </div>
                  <img src="@/assets/images/zhubgif.gif" class="recommend-img-zhibo" alt="" v-if="item.type == 'live'">
                  <img src="@/assets/images/zhubgif2.gif" class="recommend-img-zhibo" alt="" v-else-if="item.type == 'video'">
                  <img src="@/assets/images/zhubgif3.gif" class="recommend-img-zhibo" alt="" v-else-if="item.type == 'playBack'">
                </div>
                <div class="recommend-name">
                  <div class="recommend-name-toux">
                    <el-avatar :size="36" :src="item.head_img"  v-if="item.type != 'video'"/>
                  </div>
                  <div class="recommend-name-title">
                    <p class="room_title">{{item.liveroom_title}}</p>
                    <div class="anchor-name">
                      <span >{{ item.fm_zb }}</span>
                      <span>{{item.league}}</span>
                    </div>
                    <p class="">{{ item.fire }}人正在观看 </p>
                  </div>
                </div>
              </div>
              </div>
            
            </template>
          </el-skeleton>
        </div>
          </div>
        </div>        
      </div>
    </div>
    <div class="container">
      <!-- 正在热播数据列表  -->
      <div class="homeList">
     
      </div>
    </div>

  </div>
</template>

<script >
import {
  usersAttenAnchorsShow,
  popularMatchsForNows,
  nowLiveingAnchor,
  getVideoLogoMsg,
  loginRecord,
  bannerFollowingMatchs
} from "@/axios/api";
import { onMounted, ref, reactive, toRefs, watch, nextTick , computed} from "vue";
import { useRouter , useRoute} from "vue-router";
import { exportGetBrowserId } from "@/until/upmethods.js";
// 西瓜视频播放器
import Player from "xgplayer";
import { Events } from "xgplayer";
import HlsPlugin from "xgplayer-hls";
import "xgplayer/dist/index.min.css";
import { useStore } from "vuex";
import scrollcontainer from '@/views/liveInfo/components/scrollContainer/scrollContainer.vue'

export default {
  components: {
    scrollcontainer
  },

  setup() {
    
    const router = useRouter();
    const route = useRoute()
    const store = useStore();
    // const modules = ref([Autoplay, Pagination, Navigation, A11y]);
    const likeObjvuex = computed(() => store.state.likeObjvuex)
    // 跳转直播
    const skipRoute = (id, type, fire, match_id) => {
      router.push({
        path: "/liveInfo",
        query: { id, type, fire, match_id: match_id ? match_id : "" },
      });
    };
    const skipRouteInfo = () => {
      // 获取zb_id和type
      let id = state.liveingAnchor[state.iframeNav].id;
      let type = state.liveingAnchor[state.iframeNav].type;
      let match_id = "";
      if (type == "playBack") {
        match_id = state.liveingAnchor[state.iframeNav].playBack_id;
      }
      // 跳转到/liveInfo路由，并传递id和type参数
      router.push({
        path: "/liveInfo",
        query: { id, type, match_id },
      });
    };
    //查看更多
    const skipMore = () => {
      router.push({
        path: "/schedule",
      });
    };
    //返回顶部
    const scrollToTop = () => {
      // 滚动到页面顶部
      window.scrollTo({
        top: 0, // 设置滚动到的距离为0，即顶部
        behavior: "smooth", // 缓慢滚动
      });
    };
    const state = reactive({
      skeletonBool: true,
      iconZantingBool: true,
      userInfoAds: {},
      navigation2: {
        nextEl: ".button-next2",
        prevEl: ".button-prev2",
      },
      navigation: {
        nextEl: ".button-next",
        prevEl: ".button-prev",
      },

      homeTopbg: "https://home.xm888.app/Public/icon/backgroup.png",
      anchorsList: [], //热门主播列表
      infoList: [], //正在热播列表
      absObj:{},
      liveingAnchor: [], // 顶部正在热播
      iframeUrl: "",
      iframeNav: 0,
      scrollY: 0,
      scrollYBool: false,
      logomsgObj: {},
      iframeType: "",
      m3u81: "",
      cookieID: "",
      toux: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      m3u81VideoIcon: true,
      systemMessage:{},
      followingMatchs:[],
      darkMode:computed(()=>store.state.darkMode)
    });
    //获取系统消息
    const wayGetVideoLogoMsg = () => {
      getVideoLogoMsg()
        .then((res) => {
          state.systemMessage = res.data
          if (res.data.ads.ads.length > 0) {
            state.userInfoAds = res.data.ads.ads[0];
          }
        })
        .catch((err) => {});
    };
    //监听页面滚动距离
    window.addEventListener("scroll", function () {
      state.scrollY = window.scrollY;
    });
    //监听当前页面距离顶部的高度
    watch(
      () => state.scrollY,
      async (newQuestion, oldQuestion) => {
        if (newQuestion >= 666) {
          state.scrollYBool = true;
        } else {
          if (state.scrollYBool == false) return;
          state.scrollYBool = false;
        }
      }
    );
    //请求热门主播
    const wayusersAttenAnchorsShow = () => {
      if (window.innerWidth > 800) {
        usersAttenAnchorsShow({ uid: 0, cid: state.cookieID })
          .then((res) => {
            state.anchorsList = res.data;
          })
          .catch((err) => {});
      }
    };
    //请求正在热播
    const waypopularMatchsForNows = () => {
      popularMatchsForNows({ uid: 0, type: 1 })
        .then((res) => {
          state.absObj = res.ad_img.ads
          state.infoList = res.data;
          state.skeletonBool = false;
          scrollWidth.value = scrollbarReful.value.scrollWidth
        })
        .catch((err) => {});
    };
    // 请求顶部推荐直播
    const waynowLiveingAnchor = () => {
      nowLiveingAnchor({ uid: 0 })
        .then((res) => {
          state.liveingAnchor = res.data;
          if (res.data[0]) {
            state.iframeUrl = res.data[0].pull_address;
            state.m3u81 = res.data[0].m3u81;
            state.iframeType = res.data[0].type;
            Playerxgviode(res.data[0].m3u81);
          }
        })
        .catch((err) => {});
    };
    //切换顶部直播iframe
    const wayiframeNav = (i) => {
      state.iframeNav = i;
      // state.iframeUrl = state.liveingAnchor[i].pull_address;
      state.m3u81 = state.liveingAnchor[i].m3u81;
      state.iframeType = state.liveingAnchor[i].type;
      Playerxgviode(state.liveingAnchor[i].m3u81);
    };
    const Playerv = ref(null);
    const Playerxgviode = (url) => {
      Playerv.value = new Player({
        id: "mse",
        url: url,
        pip: true,
        height: "100%",
        width: "100%",
        autoplay: true,
        isLive: state.iframeType == "playBack" ? false : true,
        playbackRate: false,
        plugins: [HlsPlugin],
        currentOrigin: true,
        danmu: {
          comments: [],
          area: {
            start: 0,
            end: 0.5,
          },

          defaultOpen: true, 
        },
        icons:{
          play:`<div style="width:24px;height:24px;padding-left:10px"><img src="https://www.mengmazb.com/Public/icon/bf.png"/></div>`,
          pause:`<div style="width:24px;height:20px;padding-left:10px"><img src="https://www.mengmazb.com/Public/icon/zt.png"/></div>`,
          fullscreen:`<div style="width:24px;height:24px;"><img src="https://www.mengmazb.com/Public/icon/qp.png"/></div>`,
          volumeMuted:`<div style="width:24px;height:24px"><img src="https://www.mengmazb.com/Public/icon/jy.png"/></div>`,
          volumeLarge:`<div style="width:24px;height:24px"><img src="https://www.mengmazb.com/Public/icon/yl.png"/></div>`,
          volumeSmall:`<div style="width:24px;height:24px"><img src="https://www.mengmazb.com/Public/icon/yl.png"/></div>`
        }
      });
      // 创建完之后
      // 视频播放监听事件
      Playerv.value.on(Events.PLAY, () => {
        state.m3u81VideoIcon = false;
      });
      // 视频暂停事件
      Playerv.value.on(Events.PAUSE, () => {
        state.m3u81VideoIcon = true;
      });
    };
    const spikRouter = (url) => {
      if (url == route.path) return window.location.reload();
      router.push(url);
      wayloginRecord();
    }
    //记录用户活跃时间
    const wayloginRecord = () => {
      loginRecord({
        uid: store.state.userInfomm.id || "",
        cid: state.cookieID,
        type: "1",
      })
        .then((res) => {})
        .catch((err) => {});
    }
    // 热门直播
    const scrollbarRef = ref(null)
    const scrollbarReful = ref(null)
    const scrollWidth = ref(0) //返回元素的整个宽度（包括带滚动条的隐蔽的地方）
    const scrollNumber = ref(0)
    const handscorllbarRight = ()=>{
      scrollbarRef.value.setScrollLeft(scrollNumber.value + 400)   
    }
    const handscorllbarLeft = ()=>{
      scrollbarRef.value.setScrollLeft(scrollNumber.value - 400) 
   }
    const scrollscrollbarRef = (ev)=>{
      scrollNumber.value = ev.scrollLeft
    }
    // 获取首页 20 厂比赛
    const waybannerFollowingMatchs = ()=>{
      bannerFollowingMatchs({uid:0}).then(res=>{
        state.followingMatchs = res.data
      }).catch(err=>{})
    }
    onMounted(() => {
      state.cookieID = exportGetBrowserId();
      waynowLiveingAnchor();
      waypopularMatchsForNows();
      waybannerFollowingMatchs()
      wayusersAttenAnchorsShow();
      wayGetVideoLogoMsg();
      
    });

    return {
      ...toRefs(state),
      skipRoute,
      skipMore,
      scrollToTop,
      wayusersAttenAnchorsShow,
      waypopularMatchsForNows,
      waynowLiveingAnchor,
      wayiframeNav,
      skipRouteInfo,
      spikRouter,
      handscorllbarRight,
      handscorllbarLeft,
      scrollscrollbarRef,
      scrollcontainer,
      likeObjvuex,
      scrollbarRef,
      scrollbarReful

    };
  },
};
</script>

<style lang="scss" scoped>
.homePage {
  width: 100%;
  a {
    color: #666;
    text-decoration: none;
  }
  .container {
    width: 1200px;
    margin: 0 auto;
    // padding-bottom: 50px;

    .project {
      width: 100%;
      margin: auto;
      padding: 40px 0 20px;
      &-swiper {
        position: relative;
        margin: auto;
        swiper {
          width: 100%;
          swiper-slide {
            height: 100%;
            box-shadow: 0 3px 6px 1px rgba(0, 0, 0, 0.16);
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            border: 1px solid #e4e5ea;
            //width: 100%;
            background: #f9fafe;
          }
        }
        .button-prev {
          position: absolute;
          top: 50%;
          left: -45px;
          transform: translateY(-50%);
          cursor: pointer;
        }
        .button-next {
          position: absolute;
          top: 50%;
          right: -45px;
          transform: translateY(-50%);
          cursor: pointer;
        }
      }

      .srcorllbarBox {
        flex-shrink: 0;
        width: 240px;
        height: 100%;
        background: #fff;
        box-sizing: border-box;
        padding: 16px 16px 20px;
        .boxData {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .left {
            p {
              img {
                width: 24px;
                height: 24px;
                vertical-align: middle;
              }
              span {
                display: inline-block;
                width: 112px;
                margin: 0 0 0 6px;
                font-size: 14px;
                font-weight: 400;
                height: 24px;
                line-height: 24px;
              }
            }
          }
          .righte {
            cursor: pointer;
            padding: 4px 10px;
            font-size: 14px;
            color: #fff;
            background: #fe4b4c;
          }
        }
        .boxtop {
          color: #777;
          font-size: 12px;
          margin: 0 0 20px;
          height: 17px;
          line-height: 17px;
          div:nth-child(1) {
            float: left;
            img {
              width: 12px;
              height: 12px;
              vertical-align: middle;
            }
          }
          div:nth-child(2) {
            float: right;
          }
        }
      }
    }

    .swiper-content {
      text-align: center;
      height: 94px;
      margin: 40px 0;
      border-right: 1px solid #e4e5ea;

      &-text {
        margin: 11px auto 0;
        width: 127px;
        height: 25px;
        font-size: 16px;
        //font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #2f353b;
        line-height: 25px;
        // -webkit-background-clip: text;
        //-webkit-text-fill-color: transparent;
      }
    }


    .homeList {
      margin-top: 15px;
      .dataList { 
        

        .placeholder {
          margin-left: auto;
          margin-right: auto;
        }
      }
      .listTitle {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #777;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 10px;

        .imgone {
          width: 180px;
        }
        span {
          margin-top: 12px;
          color: #777;
          font-size: 14px;
          font-weight: 400;
          background: url("@/assets/images/icon-more.png") no-repeat right;
          padding-right: 13px;
          background-position: right center;
          background-size: 10px auto;
          cursor: pointer;
          text-decoration: none;
          float: right;
        }
      }
    }
  }

  .homeTop {
    width: 100%;
    display: flex;
    .h-content{
      flex: 1;
      .hot2{
      .title{
        display: flex;
        align-items: center;
        margin-top: 20px;
        .title-p{
          font-size: 26px;
          font-weight: 500;
        }
      }
        .recommend{
          display: flex;
          flex-wrap: wrap;
          margin-top: 30px;
          margin-bottom: -25px;
          .recommend-conten{
            width: 20%;
            min-width: 200px;
            height: auto;
            padding-bottom: 20px;
            flex-shrink: 0;
            padding-right: 10px;
            margin-top: 10px;
            cursor: pointer;
            .recommend-name{
              margin-top: 13px;
              display: flex;
              .recommend-name-toux{

              }
              .recommend-name-title{
                flex: 1;
                min-width: 0;
                padding-left: 10px;
                font-weight: 400;
                .room_title{

                }
                p:first-of-type{
                  font-size: 15px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 1;
                  -webkit-box-orient: vertical;
                }
                p:nth-of-type(2){
                  color: #aaa;
                  font-size: 13px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;

                }
                .anchor-name{
                  display: flex;
                  justify-content: space-between;
                  span{
                    color: #aaa;
                    font-size: 13px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                  }
                }
              }
            }
            .recommend-img{
              width: 100%;
              padding-top: 66.67%;
              border-radius: 6px;
              overflow: hidden;
              position: relative;
              &:hover .animaimg img{
                transform: scale(1.3);
              }
              .recommend-img-zhibo{
                position: absolute;
                right: 10px;
                top: 10px;
                width: 80px;
              }

              .animaimg{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                -o-object-fit: fill;
                object-fit: fill;

                img{
                  width:100%;
                  height:100%;
                  transition: all .5s;
                  vertical-align: top;
                  opacity: 1;
                }
              }
            }
          }
        }
      }
      .hot{
        .scrollbar-flex-content{
          ul{
            list-style: none;
          }
          .hot-conte{
            height: 120px;
            display: flex;
            align-items: center;
            margin-top: 30px;
            margin-bottom: 12px;

            li{
              width: 280px;
              height: 120px;
              flex-shrink: 0;
              padding: 5px 10px;
              margin-right: 10px;
              background-color: #fff;
              cursor: pointer;

              .top{
                width: 100%;
                display: flex;
                font-size: 12px;
                justify-content: space-between;
                .rmlogin{
                  display: flex;
                  align-items: center;
                  img{
                    width: 14px;
                    margin-right: 5px;
                  }
                  span{
                    color: #f0082e;
                  }
                }
                span:first-of-type {
                  flex: 1;
                }
                span{
                  color: #8f8f8f;
                }
                .ovehide{
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
              }
              .senb{
                margin: 15px 0;
                position: relative;
                height: 67px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .senb-bf{
                  width: 85px;
                  text-align: center;
                  margin-top: -15px;
                  font-size: 29px;
                  font-weight: 700;
                  font-family: DIN Condensed-Bold, DIN Condensed;
                }
                .senb-cons{
                  display: flex;
                  width: calc(50% - 42.5px);
                  align-items: center;
                  justify-content: center;
                  font-size: 14px;

                  .senb-cons-img{
                    width: 100%;
                    text-align: center;

                  }
                  .ovehide{
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                  }
                }
               
              }
            }
          }
        }
        .title{
          display: flex;
          align-items: center;
          margin-top: 20px;
          justify-content: space-between;
          .title-p{
            font-size: 26px;
            font-weight: 500;
          }
          .title-right{
            display: flex;
            align-items: center;
            img{
              border: none;
    vertical-align: middle;
    -webkit-user-drag: none;
              width: 24px;
              margin: 0 5px;
              cursor: pointer;  
            }
          }
        }
      }
      .advertisement{
        position: relative;
        cursor: pointer;
        p{
          position: absolute;
          right: 10px;
          top: 0;
          color: #fff;
          padding: 0 3px;
          font-size: 12px;
          background-color: rgba(0, 0, 0, .5);
        }
      }
      .homeindx{
        max-width: 75vw;
        padding: 0;
        .zhibo{
          display: flex;
          height: 620px;
          margin-bottom: 45px;
          .zhibo-left:hover{
            .welcome{
              display: block;
            }
          }
          .zhibo-left{
            flex: 1;
            height: 100%;
            position: relative;
            .zhibo-left-video{
              background-color: #fff;
              height: 100%;
              .video{
                width: 100%;
                height: 100%;
                position: relative;
              }
              .videcons-gg{
                width: 100%;
                height: 30px;
                overflow: hidden;
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 90;
                background: #6ae35e;
              }
            }
            .welcome{
              width: 220px;
              position: relative;
              top: -55%;
              left: calc(50% - 110px);
              right: calc(50% - 110px);
              overflow: hidden;
              display: none;
              cursor: pointer;
              z-index:90;
              p{
                width: 220px;
                height: 55px;
                color: #fff;
                line-height: 55px;
                text-align: center;
                font-weight: 700;
                font-size: 23px;
                border-radius: 6px;
                background: #f0082e;
              }
            }
            .iconZanting{
              position: absolute;
              width: 120px;
              height: 120px;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              background-image: url(https://www.xm888.app/Public/icon/play.png);
              background-size: 100% 100%;
              background-repeat: no-repeat;
              background-position: center;
              pointer-events: none;
            }
          }
          .zhibo-right{
            width: 340px;
            height: 100%;
            margin-left: 10px;
            .el-scrollbar{
   
                        --el-scrollbar-opacity: 0.3;
                        --el-scrollbar-bg-color: #909399;
                        --el-scrollbar-hover-opacity: 0.5;
                        --el-scrollbar-hover-bg-color: #909399;
                        overflow: hidden;
                        position: relative;
                        height: 100%;
                .el-scrollbar__wrap--hidden-default{
                  scrollbar-width: none;
                }
                .el-scrollbar__wrap {
                    overflow: auto;
                    height: 100%;

                    .el-scrollbar__view{
                      .usndf{

                        li:first-of-type{
                            margin-top: 0;
                        }
                        li{
                          height: 170px;
                          cursor: pointer;
                          position: relative;
                          margin: 5px 0;
                          &:hover{
                            .usndf-bg{
                              background: linear-gradient(90deg, #f0082e, rgba(120, 4, 23, 0))
                            }
                          }
                          
                          .usndf-bg{
                            width: 30%;
                            height: 100%;
                            position: absolute;
                            left: 0;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            .iconZanting{
                              position: absolute;
                              width: 120px;
                              height: 120px;
                              top: 50%;
                              left: 50%;
                              transform: translate(-50%, -50%);
                              background-image: url(https://www.xm888.app/Public/icon/play.png);
                              background-size: 100% 100%;
                              background-repeat: no-repeat;
                              background-position: center;
                              // z-index: 99;
                              pointer-events: none;
                            }
                          }
                          .usndfbg{
                            background: linear-gradient(90deg, #f0082e, rgba(120, 4, 23, 0));
                          }
                          .el-image{
                            width:100%;
                            height:100%;
                            position: relative;
                            display: inline-block;
                            overflow: hidden;
                            img{
                              object-fit: cover;
                              width:100%;
                              height:100%;
                            }
                          }
                        }
                      }
                      ul{
                        list-style:none
                      }
                    }
                }
              
            }
          }
        }
      }
    }
    .home-right-nav{
      height: 100%;
      overflow: hidden;
      .upsplo-bos{
        position: fixed;
        left: -15px;
        bottom: 3vh;
        z-index: 130;
        .lohuuo-hongbao{
          position: absolute;
          top: -107px;
          left: 50px;
          width: 170px ;
          z-index: 99;
        
        }
        .lohuuo{
            width: 210px;
            padding: 15px 5px;
            border-radius: 10px;
            background: #fff;
            position: relative;
            z-index: 120;
            margin-left: 30px;
            p{
              font-size: 12px;
              color: #222;
              text-align: center;
              line-height: 22px;
              display: block !important;
            }
            .erweima{
              width: 100px;
              min-height: 100px;
              margin: 0 auto;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: 10px;
              border-radius: 10px;
              margin-bottom: 10px;
              background-color: #eaeaea;
              img{
                width: calc(100% - 10px) !important;
                 height: calc(100% - 10px) !important;
              }
            }
          }
      }
      .nabsin{
        width: 240px;
        height: 100%;
        position: relative;
        transition: width .3s;

        .nav{
          width: 240px;
          height: 100%;
          transition: width .3s;
          position: fixed;
          top: 80px;
          left: 0;
          z-index: 100;
          ul{
            list-style:none
          }
          .nav-content{
            padding: 0 20px;

            li:not(:first-child):hover{
              img{
                width:26px;
                height:26px;
              }
              p{
                color: #222;
                font-size: 16px;
                font-weight: 700;
              }
            }
            li{
              display: flex;
              align-items: center;
              height: 60px;
              padding: 0 10px;
              cursor: pointer;
              position: relative;

              img{
                width: 22px;
                height: 22px;
                transition: all .2s;
              }
              p{
                margin-left: 10px;
                min-width: 60px;
                opacity: 1;
                line-height: 30px;
                color: #898989;
                transition: all .2s;
                position: relative;
              }
            }
            .bgcole{
              p{
                color: #222;
                font-size: 16px;
                font-weight: 700;
              }
            }
          }
        }
      }
    }

  }
}

@media(max-width: 1500px) {

  .upsplo-bos {
        left: -10px;
        bottom: 3vh;
    }
    .lohuuo-hongbao {
        width: 150px !important;
        top: -80px !important;
    }
    .lohuuo {
        width: 180px !important;
        padding: 10px 5px !important;
        p{
          line-height: 20px !important;
        }
        .erweima{
          width: 90px !important;
        min-height: 90px !important;
        }
    }
  .nav .nav-content .bgcole img, .nav .nav-content li:hover img {
        width: 23px !important;
        height: 23px !important;
  }
  .nav .nav-content .bgcole p {
        font-size: 14px !important;
        font-weight: 700 !important;
    }
  .nav .nav-content li{
        height: 40px !important;
    }
  .nabsin, .nav {
        width: 220px !important;
    }
    .zhibo {
        height:560px!important
    }

    .zhibo-right {
        width: 280px!important
    }

    .zhibo-right .usndf li {
        height: 140px!important
    }
    .lohuuo {
        width:180px;
        padding: 10px 5px
    }

    .lohuuo p {
        line-height: 20px
    }

    .lohuuo .erweima {
        width: 90px;
        min-height: 90px
    }

    .lohuuo-hongbao {
        width: 150px;
        top: -80px
    }

    .upsplo-bos {
        left: -10px;
        bottom: 3vh
    }

    .nabsin,.nav {
        width: 220px
    }

    .nav .nav-content li {
        height: 40px
    }

    .nav .nav-content li p {
        font-size: 13px
    }

    .nav .nav-content li:hover p {
        color: var(--fontcolor);
        font-size: 14px;
        font-weight: 700
    }

    .nav .nav-content .bgcole img,.nav .nav-content li:hover img{
        width: 23px;
        height: 23px
    }

    .nav .nav-content .bgcole p {
        font-size: 14px;
        font-weight: 700
    }
}


// 黑暗模式 
.darkhomePage{
  color:#fff !important;
  .home-right-nav{
    .nav{
      .nav-content{
      
      li:not(:first-child):hover{
        p{
          color:#f8f8f8 !important
        }
      }
      .navrings{
        .hotImges{
          width: 25px !important;
          height: auto !important;
          position: absolute;
          right: 0;
        }
      }
        .bgcole{

          p{
            color: #fff !important;
          }
     
        }
      }
    }
    .lohuuo{
      background: #202020 !important;
      p{
        color:#f8f8f8 !important
      }
    }
  }
  .hot{
    .el-scrollbar{
      .hot-conte{
        li{
          background-color: #202020 !important;
        }
      }
    }
    .title{
      .title-p{
     
      }
    }
  }
}
</style>